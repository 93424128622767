<template>
    <div>
         <div class="header">
             <span @click="gouot"></span>
             <span>AGV叉车任务列表</span>
             <span @click="islogout">退出</span>
        </div>
        <div class="content-btn">
             <van-button class="btn"  size="large" type="info" @click="GO(1)">AGV调度</van-button>
             <van-button class="btn"  size="large" type="info" @click="GO(2)">AGV库位</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            GO(i) {
                if(i==1){
                    this.$router.push('/Agvlist')
                }else{
                    this.$router.push('/AdvStorage')

                }
            },
            gouot(){
                this.$router.push('/guide')
            }
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
.content-btn{
    padding-top: 50px;
    margin-top: 50px;
    padding: 20px;
    .btn{
        margin: 40px 0;
    }
}
</style>